import React from 'react'

import Backend from '../utils/Backend'
import AuthManager from '../utils/AuthManager'

import FeedsTable from './components/tables/FeedsTable'


export default class Feeds extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  render() {
    return (
      <div className="row">
        <FeedsTable />
      </div>
    )
  }
}
