import React, { Component } from "react";

export default class TextInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      label: props.label,
      name: props.name,
      placeholder: props.placeholder,
      value: props.value,
      spanClassname: props.spanClassname,
      showSpanDanger: props.showSpanDanger,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  render() {
    const {
      label,
      placeholder,
      name,
      value,
      spanClassname,
      showSpanDanger,
    } = this.state;

    return (
      <div className="form-group row">
        <label className="col-lg-3 col-form-label">
          {label} {showSpanDanger && <span className="text-danger">*</span>}
        </label>
        <div className="col-lg-9 my-auto input-icon">
          <input
            type="text"
            className={`form-control form-control-solid ${!spanClassname && "input-padding-left"}  ?  `}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={this.props.onChange}
          />
          {spanClassname && (
            <span>
              <i className={spanClassname}></i>
            </span>
          )}
        </div>
      </div>
    );
  }
}
