import React from 'react'

import AuthManager from '../utils/AuthManager'

import Aside from './components/Aside'
import AccountSettings from './components/settings/AccountSettings'
import PasswordSettings from './components/settings/PasswordSettings'
import StripeSettings from './components/settings/StripeSettings'

import Backend from '../utils/Backend'

const TAB_ACCOUNT = {
  title:"Account Settings",
  subtitle:"Manage your email/password",
  icon:"cogwheel",
}

const TAB_PASSWORD = {
  title:"Password Settings",
  subtitle:"Manage your password",
  icon:"lock",
}

const TABS = [
  TAB_ACCOUNT,
  TAB_PASSWORD,
]

const TAB_BANK_DETAILS = {
  title:"Bank Account",
  subtitle:"Connect your bank account",
  icon:"piggy-bank",
}

export default class Settings extends React.Component {
  constructor(props) {
    super(props)

    let tabs = [...TABS]

    this.state = {
      tabs,
      activeTabIndex: 0,
    }
  }

  _renderContent(tabIndex){
    let tab = this.state.tabs[tabIndex]
    switch(tab){
      case TAB_ACCOUNT:     return <AccountSettings/>
      case TAB_PASSWORD:    return <PasswordSettings/>
      case TAB_BANK_DETAILS:return <StripeSettings/>
      default:              return null
    }
  }

  render() {
    let {
      tabs,
      activeTabIndex
    } = this.state

    return (

        <div className="kt-portlet">
          <div className="kt-portlet__body kt-portlet__body--fit">
            <div className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">

              <Aside
                tabs={tabs}
                activeTabIndex={activeTabIndex}
                onClick={(tab, index) => {
                  return this.setState({ activeTabIndex: index })
                }}
              />

              <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
                <form className="kt-form w-100" id="kt_form">

                  { this._renderContent(activeTabIndex) }

                </form>
              </div>
            </div>
          </div>
        </div>
    )
  }
}
