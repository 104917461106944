import React from 'react'
import Modal from 'react-bootstrap/Modal'

import AsyncSelect from '../common/AsyncSelect'

import Div from '../layouts/Div'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from "../../../utils/Notify"


export default class FeedModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      data: {}
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  componentDidMount(){

  }

  _setup(){
    let { website } = this.state
    this.setState({ loading: true })
    Backend.getHomepage(website)
    .then(page => {
      this.setState({
        data: {
          page
        },
        loading: false
      })
      console.log("aaa", page)
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
      this.props.onCancel()
    })
  }

  _handleChange(e) {
    let { data } = this.state
    data[e.target.name] = e.target.value
    this.setState({ data })
  }

  _isFormValid(){
    let {
      data,
      website
    } = this.state

    let {
      user,
      filename,
      publisher_number
    } = data

    let error = null
    if(!website){
      error = "Please select a website"
    }
    else if(!user){
      error = "Please enter a valid user"
    }
    else if(!filename){
      error = "Please select a valid link"
    }
    else if(!publisher_number){
      error = "Please select a valid publisher number"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _getOptions(websites){
    return websites.map(website => ({
      value: website.id,
      label: website.name,
      data: website
    }))
  }

  _connect(){
    let {
      data,
      website,
    } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })
    Backend.connectFeed({ data, website: website.id, source: "indeed" })
    .then(feed => {
      this.props.onCreated(feed)
      this.setState({
        loading: false,
        data: {},
        website: null
      })
      let message = "Feed successfully connected!"
      Notify.success(message)
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  render() {
    let {
      data,
      show,
      loading,
      website,
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Connect Feed</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Div
            disabled={loading}
            spinner={true}
          >
            <div className="form-group">
              <label>Source</label>
              <input
                type="text"
                name="source"
                className="form-control"
                value={"Indeed"}
                disabled
              />
            </div>

            <div className="form-group">
              <label>Website</label>
              <AsyncSelect
                endpoint={window.Api.Websites}
                value={ website ? { label: website.name, value: website.id } : null }
                onSelected={website => {
                  this.setState({ website })
                }}
                getOptions={pages => this._getOptions(pages)}
              />
            </div>

            <div className="form-group">
              <label>User</label>
              <input
                type="text"
                name="user"
                className="form-control"
                value={ data.user }
                onChange={e => this._handleChange(e)}
              />
            </div>

            <div className="form-group">
              <label>Link</label>
              <input
                type="text"
                name="filename"
                className="form-control"
                value={ data.filename }
                onChange={e => this._handleChange(e)}
              />
            </div>

            <div className="form-group">
              <label>Publisher Number</label>
              <input
                type="text"
                name="publisher_number"
                className="form-control"
                value={ data.publisher_number }
                onChange={e => this._handleChange(e)}
              />
            </div>

          </Div>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            disabled={loading}
            onClick={() => this._connect()}
          >
            Connect
          </button>

        </Modal.Footer>

      </Modal>
    )
  }
}

FeedModal.defaultProps = {
    loading: false,
}
