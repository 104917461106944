import FetchHelper from './FetchHelper'
import AuthManager from './AuthManager'

export default class Backend {

  static getSectors(){
    return FetchHelper.get(`${window.Api.Sectors}?paginated=false`)
  }

  static createWebsite(website){
    let data = Backend._getWebsiteData(website)
    return FetchHelper.post(`${window.Api.Websites}`, data)
  }

  static updateWebsite(website){

    let data = {
      name: website.name,
      slug: website.newSlug || website.slug,
      demo: website.demo,
      status: website.status,
      keywords: website.keywords,
      gtm_id: website.gtm_id,
      max_no_of_uploads: website.max_no_of_uploads,
      max_upload_size: website.max_upload_size,
      once_off: website.once_off,
      recurring: website.recurring,
      description: website.description,
      primary_color: website.primary_color,
      favicon_id: website.favicon?.id,
      job_name: website.job_name,
      jobs_name: website.jobs_name,
      company_name: website.company_name,
      companies_name: website.companies_name,
      article_name: website.article_name,
      articles_name: website.articles_name,

      view_job_name: website.view_job_name,
      view_jobs_name: website.view_jobs_name,
      search_jobs_name: website.search_jobs_name,
      search_jobs_placeholder_name: website.search_jobs_placeholder_name,
      browse_jobs_name: website.browse_jobs_name,

      apply_name: website.apply_name,
      job_description_name: website.job_description_name,


      search_result_redirect: website.search_result_redirect,
      hide_company_names: website.hide_company_names,
      hide_job_categories: website.hide_job_categories
    }

    if(website.commission){
      data.commission = website.commission
    }

    if(website.monthly_license){
      data.monthly_license = website.monthly_license
    }

    if(website.company_logo_id){
      data.company_logo_id = website.company_logo_id
    }

    return FetchHelper.patch(`${window.Api.Websites}/${website.slug}`, data)
  }

  static _getWebsiteData(website){
    let data = { ...website }

    if(data.template){
      data.template_id = data.template.id
    }

    if(!data.url || data.url === ""){
      delete data.url
    }
    else{
      data.url = "http://" + data.url
    }

    return data
  }

  static publish(website){
    return FetchHelper.patch(`${window.Api.Websites}/${website.slug}`, { status: "live", slug: website.slug.replace("-archived", "") })
  }

  static unpublish(website){
    return FetchHelper.patch(`${window.Api.Websites}/${website.slug}`, { status: "draft" })
  }

  static archive(website){
    return FetchHelper.patch(`${window.Api.Websites}/${website.slug}`, { status: "shutdown", slug: website.slug + "-archived" })
  }

  static createTransfer(transfer){
    return FetchHelper.post(`${window.Api.Transfers}`, transfer)
  }

  static async updateAccount(){
    return true
  }

  static slugify(name){
    return FetchHelper.post(`${window.Api.Websites}/slugify`, { name })
  }

  static updateCoupon(coupon){
    return FetchHelper.patch(`${window.Api.Coupons}/${coupon.id}`, coupon)
  }

  static createCoupon(coupon){
    return FetchHelper.post(`${window.Api.Coupons}`, coupon)
  }

  static createLoginHash(website){
    return FetchHelper.post(`${window.Api.Hashes}`, { type: "login", company_id: website.company_id})
  }

  static uploadFile(file, endpoint, params){
    let data = new FormData()

    data.append("file", file, file.name)
    for(var key in params){
      data.append(key, params[key])
    }

    return FetchHelper.post(endpoint, data, true)
  }

  static uploadImage(image, type="photo"){
    let data = new FormData()

    data.append("file", image, image.name)
    data.append("type", type)

    return FetchHelper.post(window.Api.Images, data, true)
  }

  static addImage(data){
    data.type = "photo"
    return FetchHelper.post(window.Api.Images, data)
  }

  static getStats(){
    let endpoint = window.Api.Stats
    return FetchHelper.get(endpoint)
  }

  static getPackage(id){
    return FetchHelper.get(`${window.Api.Packages}/${id}`)
  }

  static updatePackage(pkg){
    let data = this._parsePackage(pkg)
    return FetchHelper.patch(`${window.Api.Packages}/${pkg.id}`, data)
  }

  static createPackage(pkg){
    let data = this._parsePackage(pkg)
    data.featured_image_type = data.featured_image_type || "illustration"
    return FetchHelper.post(`${window.Api.Packages}`, data)
  }

  static _parsePackage(pkg){
    let data = JSON.parse(JSON.stringify(pkg))

    data.background = data.background?.id
    data.foreground = data.foreground?.id
    data.associate = data.associate?.id
    data.featured_image = data.featured_image?.id

    data.title = data.title || null
    data.subtitle = data.subtitle || null
    data.submit_cta = data.submit_cta || null
    data.subline = data.subline || null

    data.benefits = data.benefits.map(benefit => {
      benefit.subtitle = benefit.subtitle || null
      return benefit
    })

    if(data.templates){
      data.templates = data.templates.map(template => template.id)
    }

    data.amount = data.amount || 0

    return data
  }

  static saveNote(note){
    return FetchHelper.post(window.Api.Notes, note)
  }

  static getNotes(contact){
    return FetchHelper.get(`${window.Api.Notes}?contact_id=${contact.id}`)
  }

  static deleteNote(note){
    return FetchHelper.delete(`${window.Api.Notes}/${note.id}`)
  }

  static updateContact(contact){
    return FetchHelper.patch(`${window.Api.Contacts}/${contact.id}`, contact)
  }

  static createTemplate(template){
    let data = JSON.parse(JSON.stringify(template))
    data.page = template.page.id
    data.sector = 1
    return FetchHelper.post(window.Api.Templates, data)
  }

  static updateTemplate(template){
    let data = JSON.parse(JSON.stringify(template))
    data.page = template.page.id
    return FetchHelper.patch(`${window.Api.Templates}/${template.id}`, data)
  }

  static getHomepage(website){
    return FetchHelper.get(`${window.Api.Pages}?website_id=${website.id}&is_homepage=true`)
    .then(response => response.results[0])
  }

  static getStripeConnectUrl() {
    return FetchHelper.get(window.Api.Stripe)
  }

  static connectStripeAccount(data) {
    return FetchHelper.post(window.Api.Stripe, data)
  }

  static getCompany(id){
    return FetchHelper.get(`${window.Api.Companies}/${id}`)
  }


  static getDomain(website){
    let url = window.Api.Domains + "?website_id=" + website.id
    return FetchHelper.get(url)
  }

  static deleteDomain(domain){
    return FetchHelper.delete(window.Api.Domains + "/" + domain.id)
  }

  static searchDomains(data){
    return FetchHelper.post(window.Api.Domains + "/check", data)
  }

  static purchaseDomain(data){
    return FetchHelper.post(window.Api.Domains, data)
  }

  static renewDomain(domain){
    return FetchHelper.patch(`${window.Api.Domains}/${domain.id}`, domain)
  }

  static getDNSRecords(website){
    let url = window.Api.DNSRecords + "?website_id=" + AuthManager.currentWebsite.id
    return FetchHelper.get(url)
  }

  static transferDomain(url){
    let data = {
      name: url,
      type: "transfer",
      website: AuthManager.currentWebsite.id
    }
    return FetchHelper.post(window.Api.Domains, data)
  }

  static activationCheck(domain){
    return FetchHelper.post(`${window.Api.Domains}/${domain.id}/activation-check`, {})
  }

  static updateDNS(domain, data){
    data = JSON.parse(JSON.stringify(data))
    if(data.priority){
      data.priority = parseInt(data.priority)
    }
    return FetchHelper.post(`${window.Api.Domains}/${domain.id}/records`, data)
  }

  static deleteRecord(domain, data){
    return FetchHelper.delete(`${window.Api.Domains}/${domain.id}/records`, data)
  }

  static addGmailRecords(domain){
    return FetchHelper.post(`${window.Api.Domains}/${domain.id}/gmail`)
  }

  static getBranding(){
    return FetchHelper.get(`${window.Api.Branding}?website_url=${window.location.hostname}`, false)
  }

  static updateAdmin(admin){

    let data = {
      role: admin.role,
      user: {
        first_name: admin.user.first_name,
        last_name: admin.user.last_name,
      }
    }

    if(admin.user.password){
      data.user.password = admin.user.password
    }

    return FetchHelper.patch(`${window.Api.Admin}/${admin.user.id}`, data)
  }

  static updateCompanyMember(companyMember){

    let data = {
      role: companyMember.role,
      user: {
        first_name: companyMember.user.first_name,
        last_name: companyMember.user.last_name,
        email: companyMember.user.email
      }
    }

    if(companyMember.user.password){
      data.user.password = companyMember.user.password
    }

    return FetchHelper.patch(`${window.Api.Staff}/${companyMember.user.id}`, data)
  }

  static addCompanyMember(companyMemberData){

    let data = {
      role: companyMemberData.role,
      ...companyMemberData.user
    }
    return FetchHelper.post(`${window.Api.Staff}`, data)
  }

  static deleteCompanyMember(companyMember){
    return FetchHelper.delete(`${window.Api.Staff}/${companyMember.user.id}`)
  }

  static getCRMForm(){
    let url = `${window.Api.Forms}?website_id=${AuthManager.currentWebsite.id}&type=crm`
    return FetchHelper.get(url)
      .then(response => {
        return response.results[0]
      })
  }

  static _parseSubmission(submission){
    submission.answers.map(answer => {
      if(answer.field.type != "multi_select"){
        delete answer['options_selected']
      }
      if(answer.option_selected){
        answer.option_selected = answer.option_selected.id
      }
      if(answer.field.type == 'multi_select' && answer.options_selected.length > 0){
        let options = []
        answer.options_selected.map(item => {
          options.push(item.id)
        })
        answer.options_selected = options
      }
      answer.field = answer.field.id
      answer.file = answer.file?.id
      answer.image = answer.image?.id
    })
    submission.status = submission.status?.id
    return submission
  }

  static _removeEmptyValues(obj){
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj
  };

  static updateSubmission(submission){
    submission = JSON.parse(JSON.stringify(submission))
    submission = this._parseSubmission(submission)
    submission = this._removeEmptyValues(submission)
    submission.answers.map(answer => {
      answer = this._removeEmptyValues(answer)
    })
    return FetchHelper.patch(window.Api.Submissions + '/' + submission.id, submission)
  }

  static updateSubmissionPosition(submission){
    let data = { status: submission.status, position: submission.position }
    return FetchHelper.patch(window.Api.Submissions + '/' + submission.id, data)
  }

  static updateSubmissionStatus(status){
    return FetchHelper.patch(window.Api.SubmissionStatus + '/' + status.id, status)
  }

  static createSubmission(submission){
    let data = JSON.parse(JSON.stringify(submission))
    data = this._parseSubmission(data)
    return FetchHelper.post(window.Api.Submissions, data)
  }

  static getSubmission(id){
    return FetchHelper.get(window.Api.Submissions + '/' + id)
  }

  static getCRM(){
    return FetchHelper.get(window.Api.CRM + '?website_id=' + AuthManager.currentWebsite.id)
  }

  static getLeads(){
    let url = window.Api.Submissions
    url += '?website_id=' + AuthManager.currentWebsite.id + '&form_type=crm'
    return FetchHelper.get(url)
  }

  static getLatestSubmission(){
    return FetchHelper.post(window.Api.Submissions, true)
  }

  static getForms(){
    let url = window.Api.Forms + "?website_id=" + AuthManager.currentWebsite.id
    return FetchHelper.get(url)
    .then(response => {
      return response.results
    })
  }

  static addNewSubmissionStatus(status){
    return FetchHelper.post(window.Api.SubmissionStatus, status)
  }

  static deleteSubmissionStatus(status){
    let data = {active:false}
    return FetchHelper.patch(window.Api.SubmissionStatus + '/' + status.id, data)
  }

  static getConversation(id){
    return FetchHelper.get(`${window.Api.Conversations}/${id}`)
  }

  static sendMessage(data) {
    return FetchHelper.post(`${window.Api.Messages}`, data)
  }

  static connectFeed(data) {
    return FetchHelper.post(`${window.Api.Feeds}`, data)
  }

  static deleteFeed(feed){
    return FetchHelper.delete(`${window.Api.Feeds}/${feed.id}`)
  }

}
