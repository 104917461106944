import React from "react"
import { withRouter } from "react-router-dom";

import moment from 'moment'

import AsyncSelect from '../AsyncSelect'

import BaseTable from "./BaseTable"

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import AuthManager from '../../../utils/AuthManager'
import Currency from '../../../utils/Currency'
import Notify from "../../../utils/Notify";
import Website from "../../../utils/Website";

import FeedModal from '../../components/modal/FeedModal'

const ICON = (
  <g stroke="none" strokewidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <path d="M5.84026576,8 L18.1597342,8 C19.1999115,8 20.0664437,8.79732479 20.1528258,9.83390904 L20.8194924,17.833909 C20.9112219,18.9346631 20.0932459,19.901362 18.9924919,19.9930915 C18.9372479,19.9976952 18.8818364,20 18.8264009,20 L5.1735991,20 C4.0690296,20 3.1735991,19.1045695 3.1735991,18 C3.1735991,17.9445645 3.17590391,17.889153 3.18050758,17.833909 L3.84717425,9.83390904 C3.93355627,8.79732479 4.80008849,8 5.84026576,8 Z M10.5,10 C10.2238576,10 10,10.2238576 10,10.5 L10,11.5 C10,11.7761424 10.2238576,12 10.5,12 L13.5,12 C13.7761424,12 14,11.7761424 14,11.5 L14,10.5 C14,10.2238576 13.7761424,10 13.5,10 L10.5,10 Z" fill="#000000"></path>
      <path d="M10,8 L8,8 L8,7 C8,5.34314575 9.34314575,4 11,4 L13,4 C14.6568542,4 16,5.34314575 16,7 L16,8 L14,8 L14,7 C14,6.44771525 13.5522847,6 13,6 L11,6 C10.4477153,6 10,6.44771525 10,7 L10,8 Z" fill="#000000" fillRule="nonzero" opacity="0.3"></path>
  </g>
)

const SOURCES = [
  {
    label: "All",
    value: null
  },
  {
    label: "Jobbio",
    value: "jobbio"
  },
  {
    label: "Indeed",
    value: "indeed"
  }
]

class FeedsTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      loading: true,
      filters: [],
      selectedFilter: null,
    }

    this.table = React.createRef()
  }

  _delete(feed) {
    this.setState({ loading: true })
    Backend.deleteFeed(feed)
    .then(() => {
      Notify.success("Feed disconnected successfully")
      this.setState({ loading: false })
      this.table.current.refresh()
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _getColumns() {
    let { loading } = this.state
    return [
      {
        Header: 'Created',
        id: 'created_at',
        accessor: feed => moment(feed.created_at).format('DD MMM YYYY')
      },
      {
        Header: 'Website',
        id: "website.name",
        accessor: feed => feed.website.name
      },
      {
        Header: 'Status',
        id: 'active',
        Cell: rowInfo => {
          let feed = rowInfo.original
          let label = "Active"
          let type = "success"

          if(!feed.active){
            label = "Inactive"
            type = "danger"
          }
          return label
        }
      },
      {
        Header: 'Source',
        id: 'source',
        accessor: feed => feed.source
      },
      {
        Header: 'Actions',
        width: 250,
        sortable: false,
        Cell: rowInfo => {
          let feed = rowInfo.original

          let url = Website.getCurrentDomain()
          return (
            <span>
                { feed.active &&
                  <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                    onClick={() => this._delete(feed)}
                    title={ "Disconnect" }
                    disabled={loading}
                  >
                    <i className="la la-power-off"/>
                  </a>
                }
            </span>
          )
        }
      },
    ]
  }

  render() {
    let {
      filters,
      loading,
      endpoint,
      selectedfeed,
      showFeedModal,
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.Feeds}
          title={ this.props.title }
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          showPagination={this.props.showPagination}
          showPaginationTop={this.props.showPaginationTop}
          showPaginationBottom={this.props.showPaginationBottom}
          exportButtonsEnabled={false}
          filters={SOURCES}
          searchFilterName="source"
          icon={ICON}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? 'visible' : 'hidden'
              }
            }
          }}
          enableSearch={false}
          renderToolbar={() => {
            return (
              <div class="kt-portlet__head-toolbar">
                <div class="kt-portlet__head-wrapper">&nbsp;
                  <button
                    type="button"
                    class="btn btn-brand btn-icon-sm"
                    onClick={() => this.setState({ showFeedModal: true })}
                  >
                    <i class="flaticon2-plus"></i>
                    Connect Feed
                  </button>
                </div>
              </div>
            )
          }}
        />
        <FeedModal
          show={showFeedModal}
          onCancel={() => this.setState({ showFeedModal: false })}
          onCreated={() => {
            this.setState({ showFeedModal: false })
            this.table.current.refresh()
          }}
        />

      </>
    )
  }
}

FeedsTable.defaultProps = {
  title: "Feeds",
  demo: false,
  showPagination: true,
  showPaginationTop: false,
  showPaginationBottom: true,
}

export default withRouter(FeedsTable)
