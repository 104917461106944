import React from "react"

import ImageEdit from "../common/ImageEdit"

import Div from "../layouts/Div"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Notify from "../../../utils/Notify"

export default class PasswordSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      data: AuthManager.currentUser,
    }
  }

  isFormValid(){
    let {
      data
    } = this.state

    let {
      password,
      verifyPassword
    } = data.user

    let isValid = true
    let error = null

    if(!password || password === ""){
      isValid = false
      error = "Please enter a valid password"
    }
    else if(password !== verifyPassword){
      isValid = false
      error = "Passwords don't match"
    }

    if(error){
      Notify.error(error)
    }

    return isValid
  }

  _update(e){
    e.preventDefault()
    let {
      data
    } = this.state

    if(!this.isFormValid()){
      return
    }

    this.setState({ loading: true })
    this._handleBackendCall(data)
    .then(() => {
      this.setState({
        loading: false
      })
      Notify.success("Password updated successfully!")
    })
    .catch(error => {
      this.setState({
        loading: false
      })
      Notify.error(error.message)
    })
  }

  _handleBackendCall(data){
    return Backend.updateAdmin(data)
  }

  _handleChange(e) {
    let data = this.state.data
    data[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _handleChangeUser(e) {
    let data = this.state.data
    data.user[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  render() {
    let {
      data,
      loading
    } = this.state

    return (
      <>
        <Div
          className="kt-wizard-v2__content"
          data-ktwizard-type="step-content"
          data-ktwizard-state="current"
          disabled={loading}
        >
          <div className="kt-heading kt-heading--md">Password Settings</div>
          <div className="kt-form__section kt-form__section--first">
            <div className="kt-wizard-v2__form">

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Password</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="password"
                    className="form-control form-control-solid"
                    name="password"
                    onChange={e => this._handleChangeUser(e)}
                  />
                </div>
              </div>


              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Verify Password</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="password"
                    className="form-control form-control-solid"
                    name="verifyPassword"
                    onChange={e => this._handleChangeUser(e)}
                  />
                </div>
              </div>


            </div>
          </div>
        </Div>
        <div className="kt-form__actions pull-right">
          <button
            className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
            disabled={loading}
            onClick={(e) => this._update(e)}
          >
            Save Changes
          </button>
        </div>
      </>
    )
  }
}
