import React from "react"

import lottie from 'lottie-web';

import { animationData, genericAnimationData } from '../../../assets/js/logo-black.js'

import animatedLogo from "../../../assets/media/logos/amply_logo.gif"

export default class Div extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      canRenderChildren: props.canRenderChildren
    }

    this.spinner = React.createRef()
    this.animObj = null
  }


  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _startAnimation(){
    if(!this.spinner){
      return
    }

    let animation = animationData
    let animationSpeed = 2.5
    let direction = 1

    if (window.General.Branding.Name !== "Amply" ){
      animation = genericAnimationData
      animationSpeed = 1
      direction = -1
    }

    //call the loadAnimation to start the animation
    this.animObj = lottie.loadAnimation({
      container: this.spinner, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animation // the path to the animation json
    });

    this.animObj.setDirection(direction);
    this.animObj.setSpeed(animationSpeed)

    this.animObj.play();
  }

  _stopAnimation(){
    if(!this.animObj){
      return null
    }
    this.animObj.stop();
  }

  _renderDisableView(){
    if(!this.props.disabled){
      setTimeout(() => this._stopAnimation(), 50)
      return null
    }

    setTimeout(() => this._startAnimation(), 50)

    return (
      <div className="disable-view">
        { this.props.spinner &&
          <div
            class="spinner spinner-track spinner-primary"
          >
            <div class="logo-svg">
            <img src={animatedLogo} alt="Logo" className="branding-logo h-50 w-50 rounded-circle" style={{objectFit: "cover"}} />
            </div>
            { this.props.loadingText }
          </div>
        }
      </div>
    )
  }

  _renderLoading(){
    if(this.props.renderLoading){
      return this.props.renderLoading()
    }

    return null
  }

  render() {
    let {
      canRenderChildren
    } = this.state

    if(!canRenderChildren){
      return null
    }

    return (
      <div className={"relative "+this.props.className}>
        { this.props.children }
        { this._renderDisableView() }
      </div>
    )
  }
}

Div.defaultProps = {
  canRenderChildren: true,
  loadingText: "Loading"
}
