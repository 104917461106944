import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.General = {};

window.General.WebsiteDomain = env.REACT_APP_WEBSITE

window.General.WebsiteNakedDomain = env.REACT_APP_WEBSITE_NAKED_DOMAIN

window.General.Branding = {}

window.General.Branding.Name = "Amply"
window.General.Branding.Logo = require('../assets/media/logos/amply-logo.png')
window.General.Branding.Favicon = '/amply_favicon_32x32px.png'
window.General.Branding.Domain = window.General.WebsiteNakedDomain
