import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Div from '../layouts/Div'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from "../../../utils/Notify"
import General from "../../../utils/General"

import Select from '../common/Select';

import AsyncSelect from '../common/AsyncSelect'

import ColorPicker from '../ColorPicker'

import ImagePickerModal from './ImagePickerModal'

const LOADING_GIF = Math.random() < 0.5 ? require('../../../assets/media/gifs/creating-website.gif') : require('../../../assets/media/gifs/creating-website.gif')

const TYPE_OPTIONS = [
  {
    label: "Live",
    value:false
  },
  {
    label: "Demo",
    value: "true"
  }
]

export default class CreateWebsiteModal extends React.Component {
  constructor(props){
    super(props)
    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      show: props.show,
      website: {
        status: "live",
        primary_color: "#3d85c6",
        template_id: props.targetWebsite ? null : 1,
        target_website_id: props.targetWebsite ? props.targetWebsite.id : null
      },
    }
  }

  _handleChange(e) {
    let website = this.state.website
    website[e.target.name] = e.target.value
    this.setState({ website })
  }

  _isFormValid(){
    let {
      website
    } = this.state

    let {
      demo,
      name,
      slug,
      template,
      target_website_id,
      banner_image_id,
      primary_color
    } = website

    let error = null
    if(demo == null){
      error = "Please select a type"
    }
    else if(!name || name === ""){
      error = "Please enter a valid name"
    }
    else if(!slug || slug == ""){
      error = "Please enter a valid Amply url"
    }
    else if(!banner_image_id){
      error = "Please select an image"
    }
    else if(!primary_color){
      error = "Please select a color"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _setSlug = General.debounce(() => {
    let {
      website
    } = this.state

    if(website.slug){
      return
    }

    Backend.slugify(website.name)
    .then(response => {
      let errorSlug = false
      if(website.slug && response.slug != website.slug){
        errorSlug = true
      }
      if(!website.slug){
        website.slug = response.slug
      }
      this.setState({ errorSlug, website })
    })
    .catch(error => {
      Notify.error(error.message)
    })
  }, 500)

  _checkSlug(){
    let {
      website
    } = this.state

    Backend.slugify(website.slug)
    .then(response => {
      if(response.slug != website.slug){
        this.setState({ errorSlug: true })
      }
      else{
        this.setState({ errorSlug: false })
      }
    })
    .catch(error => {
      Notify.error(error.message)
    })
  }

  _onCreateClicked(){
    let {
      website
    } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })
    Backend.createWebsite(website)
    .then(website => {
      this.setState({
        website: website,
        created: true,
        loading: false
      })
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _onEditWebsiteClicked(website){

    this.props.onCancel()
    this.setState({ loadingHash: true })
    let url = Website.getSiteUrl(website) + "?edit=true"
    let editWebsiteTab = window.open();
    Backend.createLoginHash(website)
    .then(hash => {
      editWebsiteTab.location.href = url+"&h="+hash.value
      this.setState({ loadingHash: false })
    })
    .catch(error => {
      editWebsiteTab.location.href = url
      this.setState({ loadingHash: false })
    })
  }

  _getTemplateOption(template){
    if(!template){
      return null
    }

    return {
      value: template.id,
      label: template.name,
      data: template
    }
  }

  _renderContent(){
    let {
      loading,
      created
    } = this.state

    if(created){
      return this._renderCreated()
    }
    else{
      return this._renderForm()
    }
  }

  _renderLoading(){
    return (
      <Modal.Body>
        <div className="text-center my-auto">
          <img src={ LOADING_GIF } alt="Laoding" height="300px"/>
        </div>
      </Modal.Body>
    )
  }

  _renderCreated(){
    let {
      website
    } = this.state
    return (
      <>
        <Modal.Body>
          <div className="text-center my-auto">
            <h1>Success!</h1>
            <p>The website was successfully created</p>
          </div>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Close
          </button>

          <a
            href="#"
            className={`btn btn-primary`}
            onClick={e => {
              e.preventDefault()
              this._onEditWebsiteClicked(website)
            }}
          >
            View
          </a>

        </Modal.Footer>
      </>
    )
  }


  _renderForm() {
    let {
      loading,
      website,
      errorSlug,
      imageLoading,
      target_website_id,
      template
    } = this.state


    let className = "input-group-append-bg-dark "

    if(website.slug){
      className += errorSlug ? "input-group-append-bg-dark-error" : "input-group-append-bg-green"
    }

    let image = website.image

    return (
      <>
        <Modal.Body>
          <Div
            disabled={loading}
            spinner={true}
          >
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Type</label>
              <div className="col-lg-9 my-auto">
                <Select
                  value={TYPE_OPTIONS.find(option => {
                    if(option.value === "false"){
                      return website.demo === true
                    }
                    return website.demo === false
                  })}
                  className="async-select-paginate"
                  classNamePrefix="async-select-paginate"
                  onChange={option => {
                    website.demo = option.value === "true" ? true : false
                    this.setState({ website })
                  }}
                  options={TYPE_OPTIONS}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Template</label>
              <div className="col-lg-9 my-auto">
                <AsyncSelect
                    endpoint={window.Api.Templates}
                    params={{ public: true, order_by: "id" }}
                    value={ this._getTemplateOption(template) }
                    onSelected={template => {
                      website.template_id = template.id
                      this.setState({ website, template })
                    }}
                    getOptions={templates => {
                      return templates.map(template => this._getTemplateOption(template))
                    }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Site Name</label>
              <div className="col-lg-9 my-auto">
                <input
                  type="text"
                  name="name"
                  className="form-control form-control-solid"
                  value={ website.name }
                  onChange={e => {
                    website.name = e.target.value
                    this.setState({ website })
                  }}
                  onBlur={e => {
                    if(!website.slug){
                      this._setSlug()
                    }
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-lg-3 col-form-label">{window.General.Branding.Name} URL</label>
              <div className="col-lg-9 my-auto">
                <div className="input-group">
                  <input
                    type="text"
                    name="slug"
                    className="form-control form-control-solid"
                    value={ website.slug }
                    onChange={e => this._handleChange(e)}
                    onBlur={e => {
                      console.log("-------")
                      this._checkSlug()
                      console.log("QQQQQQ")
                    }}
                  />
                  <div class={`input-group-append ${className}`}>
                    <span class="input-group-text">.{window.General.Branding.Domain}</span>
                  </div>
                </div>
              </div>
            </div>

            { target_website_id == null &&
              <>
                <div class="form-group fv-plugins-icon-container">
                  <label className="mb-4">Main Banner Image</label>
                   <div
                    className="form-control form-control-solid text-center p-0 d-flex justify-content-center align-items-center cursor-pointer choose-image"
                    style={{ height: 300, overflow: 'hidden' }}
                    onClick={() => this.setState({ showImagePicker: true })}
                   >
                    { !image &&
                      <>
                        <div className="choose-image-overlay"/>
                        <div className="bg-text">
                          <p className="my-auto">Select Random Image</p>
                        </div>
                      </>
                    }
                    { (image || imageLoading) &&
                      <div className="w-100 shine shine-overlay">
                        { image &&
                          <>
                          <div className="uploaded-image" style={{ backgroundImage: "url("+image.original+")" }} ></div>
                          </>
                        }
                      </div>
                    }
                   </div>
                </div>

                <ColorPicker
                  title={ "Main Site Colour" }
                  color={ website.primary_color }
                  onColorSelected={color => {
                    website.primary_color = color
                    this.setState({ color })
                  }}
                />
              </>
            }

          </Div>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            disabled={loading}
            onClick={() => this._onCreateClicked()}
          >
            { this.props.createButtonText }
          </button>

        </Modal.Footer>
      </>
    )
  }

  render() {
    let {
      show,
      created,
      loading,
      website,
      showImagePicker
    } = this.state

    let title = loading ? "Creating Your Site..." : "Create Site"
    if(created){
      title = "Site Created"
    }
    if(this.props.targetWebsite){
      title = loading ? "Duplicating Site..." : "Duplicate Site"
      if(created){
        title = "Site Duplicated"
      }
    }
    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onCancel()}
        >
          <Modal.Header closeButton>
            <Modal.Title>{ title }</Modal.Title>
          </Modal.Header>

          { this._renderContent() }

        </Modal>
        <ImagePickerModal
            show={showImagePicker}
            onSelected={image =>  {
              this.setState({ imageLoading: true, showImagePicker: false })
              Backend.addImage(image)
              .then(image => {
                website.image = image
                website.banner_image_id = image.id
                this.setState({ website, imageLoading: false })
              })
              .catch(error => {
                Notify.error(error.message)
                this.setState({ imageLoading: false })
              })
            }}
            onHide={() => this.setState({ showImagePicker: false })}
          />
      </>
    )
  }
}

CreateWebsiteModal.defaultProps = {
    loading: false,
    createButtonText: "Create"
}
