import React from "react"

import moment from 'moment'

import AsyncSelect from '../AsyncSelect'

import BaseTable from "./BaseTable"

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import AuthManager from '../../../utils/AuthManager'
import Website from '../../../utils/Website'
import Currency from '../../../utils/Currency'

import TemplateModal from '../../components/modal/TemplateModal'


import Select from '../common/Select';
import Notify from "../../../utils/Notify";

const ICON = (
  <g stroke="none" strokewidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <path d="M5.84026576,8 L18.1597342,8 C19.1999115,8 20.0664437,8.79732479 20.1528258,9.83390904 L20.8194924,17.833909 C20.9112219,18.9346631 20.0932459,19.901362 18.9924919,19.9930915 C18.9372479,19.9976952 18.8818364,20 18.8264009,20 L5.1735991,20 C4.0690296,20 3.1735991,19.1045695 3.1735991,18 C3.1735991,17.9445645 3.17590391,17.889153 3.18050758,17.833909 L3.84717425,9.83390904 C3.93355627,8.79732479 4.80008849,8 5.84026576,8 Z M10.5,10 C10.2238576,10 10,10.2238576 10,10.5 L10,11.5 C10,11.7761424 10.2238576,12 10.5,12 L13.5,12 C13.7761424,12 14,11.7761424 14,11.5 L14,10.5 C14,10.2238576 13.7761424,10 13.5,10 L10.5,10 Z" fill="#000000"></path>
      <path d="M10,8 L8,8 L8,7 C8,5.34314575 9.34314575,4 11,4 L13,4 C14.6568542,4 16,5.34314575 16,7 L16,8 L14,8 L14,7 C14,6.44771525 13.5522847,6 13,6 L11,6 C10.4477153,6 10,6.44771525 10,7 L10,8 Z" fill="#000000" fillRule="nonzero" opacity="0.3"></path>
  </g>
)

const STATUSES = [
  {
    label: "All",
    value: "null"
  },
  {
    label: "Active",
    value: "true"
  },
  {
    label: "Inactive",
    value: "false"
  },
]

export default class TemplatesTable extends React.Component {

  constructor(props){
    super(props)


    this.state = {
      endpoint: window.Api.Templates,
      mode: props.mode,
      loading: true,
      filters: [],
      selectedFilter: null,
    }

    this.table = React.createRef()
  }

  componentDidMount(){
    //this._setup()
  }

  _toggleActive(template){
    template.active = !template.active
    return Backend.updateTemplate(template)
  }

  _onEditWebsiteClicked(website){
    this.setState({ loadingHash: true })
    let url = Website.getSiteUrl(website) + "?edit=true"
    let editWebsiteTab = window.open();
    Backend.createLoginHash(website)
    .then(hash => {
      editWebsiteTab.location.href = url+"&h="+hash.value
      this.setState({ loadingHash: false })
    })
    .catch(error => {
      editWebsiteTab.location.href = url
      this.setState({ loadingHash: false })
    })
  }

  _getColumns() {
    let columns = [
      {
        Header: 'Created',
        id: 'created_at',
        accessor: template => moment(template.created_at).format('DD MMM YYYY')
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Status',
        id: 'active',
        Cell: rowInfo => {
          let pkg = rowInfo.original
          let label = "Active"
          let type = "success"

          if(!pkg.active){
            label = "Inactive"
            type = "danger"
          }
          return label
        }
      },
      {
        Header: 'Actions',
        id: 'status',
        width: 250,
        Cell: rowInfo => {
          let template = rowInfo.original
          return (
            <span>
                <a
                  className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                  title="Edit"
                  onClick={() => this._onEditWebsiteClicked(template.page.website)}

                >
                  <i className={`la la-eye`}/>
                </a>
                <a
                  className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                  title="Edit"
                  onClick={() => this.setState({ selectedTemplate: template, showTemplateModal: true })}

                >
                  <i className="la la-edit"/>
                </a>
                <a
                  className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                  onClick={() => {
                    this._toggleActive(template)
                    .then(() => {
                      this.table.current.refresh()
                      Notify.success("Template successfully updated!")
                    })
                  }}
                  title={ template.active ? "Deactivate" : "Activate" }
                >
                  <i className="la la-power-off"/>
                </a>
            </span>
          )
        }
      },
    ]

    return columns
  }

  _renderTemplateModal(){
    let {
      selectedTemplate,
      showTemplateModal
    } = this.state

    if(!showTemplateModal){
      return null
    }

    return (
      <TemplateModal
        show={showTemplateModal}
        onCancel={() => this.setState({ showTemplateModal: false })}
        template={selectedTemplate}
        onCreated={template => {
          this.setState({ selectedTemplate: null, showTemplateModal: false })
        }}
      />
    )
  }

  render() {
    let {
      loading,
      endpoint,
      selectedTemplate,
      showTemplateModal,
    } = this.state

    const columns = this._getColumns()

    console.log("----", endpoint)
    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={endpoint}
          title={ this.props.title }
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          filters={STATUSES}
          searchFilterName="active"
          showPagination={this.props.showPagination}
          showPaginationTop={this.props.showPaginationTop}
          showPaginationBottom={this.props.showPaginationBottom}
          exportButtonsEnabled={false}
          icon={ICON}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: column.Header === "Actions" ? 0 : 10,
                overflow: column.Header === "Actions" ? 'visible' : 'hidden'
              }
            }
          }}
        />

        { this._renderTemplateModal() }

      </>
    )
  }
}

TemplatesTable.defaultProps = {
  title: "Templates",
  showPagination: true,
  showPaginationTop: false,
  showPaginationBottom: true,
}
